export default defineI18nConfig(() => ({
    legacy: false,
    locale: "ru",
    defaultLocale: "ru",
    messages: {
        ru: {
            yup: {
                "Ref(password)": "пароль",
                required: "Это обязательное поле",
                email: "E-Mail должен быть корректным",
                oneOf: `Это поле должно совпадать с полем {0}`,
                min: `Минимум {min} символов`,
                max: `Максимум {max} символов`,
            },
            breadcrumbs: {
                index: "Главная",
                contacts: "Наши магазины",
                assortment: "Ассортимент магазина",
                cart: "Корзина",
                articles: "Статьи",
                news: "Новости",
                stocks: "Ткани по акции",
                "404": "Страница не найдена",
                order_create: "Оформление заказа",
                brands: "Бренды",
                lk: "Личный кабинет",
                favorite: "Избранные",
                orders: "Заказы",
                ateliers: "Наши ателье",
                ateliers_works: "Работы мастеров",
                reviews: "Отзывы",
                order: "Заказ",
                payment_error: "Ошибка оплаты",
                payment_success: "Успешная полата",
                search: "Результаты поиска",
                support: "Обращения",
                sitemap: "Карта сайта",
                solutions: "Решения"
            },
        },
    },
}));
